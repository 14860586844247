import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Footer() {
  return <footer id="footer" className="border-0 bg-grey">
    <div className="container">
      <div className="footer-widgets-wrap pb-5 clearfix">

        <div className="row col-mb-50">
          <div className="col-md-12">

            <div className="widget clearfix">

              <StaticImage
                className="alignleft"
                src="../../static/img/logo-wide.png"
                alt="Footer Logo"
                width="200px"
                placeholder="blurred"/>

              <p>Ateros développe depuis 2018 des solutions innovantes, flexibles et adaptées à vos différentes
                problématiques. Nos produits sont développés en fonction des besoins réels des professionnels.
                Notre force réside dans un support client réactif et un développement sur-mesure de nos produits
                pour satisfaire vos besoins.</p>
              <div className="line line-sm"></div>

              <div className="row">
                <div className="col-lg-3 col-6 bottommargin-sm widget_links">
                  <ul>
                    <li><a href="#">Accueil</a></li>
                    <li><a href="#">A propos</a></li>
                    <li><a href="#">Support</a></li>
                    <li><a href="#">Contact</a></li>
                  </ul>
                </div>

                <div className="col-lg-3 col-6 bottommargin-sm widget_links">
                  <ul>
                    <li><a href="#">Ateros Designer</a></li>
                    <li><a href="#">Ateros Social</a></li>
                    <li><a href="#">Ateros Shop</a></li>
                    <li><a href="#">Ateros Invoice</a></li>
                  </ul>
                </div>

                <div className="col-lg-3 col-6 bottommargin-sm widget_links">
                  <ul>
                    <li><a href="#">Ateros Pay</a></li>
                    <li><a href="#">Ateros Host</a></li>
                    <li><a href="#">SEO</a></li>
                    <li><a href="#">Developpement sur mesure</a></li>
                  </ul>
                </div>

                <div className="col-lg-3 col-6 bottommargin-sm widget_links">

                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
    </div>

    <div id="copyrights"
         style={{
           background: "url('demos/seo/images/hero/footer.svg') no-repeat top center",
           backgroundSize: "cover",
           paddingTop: 70
         }}>
      <div className="container clearfix">

        <div className="row justify-content-between col-mb-30">
          <div className="col-12 col-lg-auto text-center text-lg-left">
            &copy; Ateros - 2021<br />
            <div className="copyright-links"><a href="#">CGV</a> / <a href="#">CGU</a> / <a href="#">Mentions
              légales</a> / <a href="#">Politique de confidentialité</a></div>
          </div>

          <div className="col-12 col-lg-auto text-center text-lg-right">
            <div className="copyrights-menu copyright-links clearfix">
              <a href="#">Press</a>/<a href="#">Legal</a>/<a href="#">Equipe</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </footer>
}
