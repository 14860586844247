import React from "react"
import Layout from "../components/layout"
import { Hero } from "../components/hero"

export default function Seo() {
  return (
    <Layout>
      <Hero image="https://static-cdn.ateros.fr/file/ateros/WJEqFts7kqWTGn14b2Hff6Wdcq3cIaypBAlXNVT8.png">
        <h1>SEO Local</h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="#">Nos services</a></li>
          <li className="breadcrumb-item active" aria-current="page">SEO</li>
        </ol>
      </Hero>

      <section id="content">

        <div className="content-wrap py-0 overflow-visible mb-0">

          <div className="card mx-auto mw-md border-0 shadow rounded-xl card-seo-about mb-0 mb-lg-0">
            <div className="card-body p-5">
              <div className="row align-items-center">
                <div className="col-12">
                  <div className="heading-block border-bottom-0 mb-0 text-center">
                    <h3 className="nott ls0 mb-3">Optimisez votre SEO pour toucher une cible locale.</h3>
                    <p>Le <b>SEO local</b> vous permet de créer des centaines de pages pour optimiser votre présence sur
                      certaines villes. Le principe est simple;
                      Nous créeons une page de présentation de votre entreprise et de vos services sur une page dédiée à
                      une
                      certaine ville pour toucher une audience
                      précise.</p>

                  </div>
                </div>

              </div>
            </div>
          </div>


          <div className="container bottommargin">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 text-center">
                <div className="heading-block border-bottom-0 center mx-auto">
                  <h3 className="nott ls0 mb-3">Quelques explications.</h3>
                  <p>Le SEO local c'est quoi exactement ? Et qu'est ce que cela apporte <b>vraiment</b> à mon
                    entreprise?
                  </p>
                </div>
              </div>

              <div className="clear"></div>

              <div className="col-lg-10">

                <div className="grid-filter-wrap justify-content-center">
                  <ul className="grid-filter style-4 customjs">

                    <li className="activeFilter"><a href="#" className="font-weight-semibold"
                                                    data-filter="all">Explications</a></li>
                    <li className=""><a href="#" className="font-weight-semibold" data-filter=".avantages">Avantages</a>
                    </li>
                    <li className=""><a href="#" className="font-weight-semibold" data-filter=".setup">Mise en place</a>
                    </li>

                  </ul>
                </div>

                <div className="clear"></div>

                <div id="faqs" className="faqs">

                  <div className="toggle faq pb-3 mb-4 explication explication">
                    <div className="toggle-header">
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-question-sign"></i>
                        <i className="toggle-open icon-question-sign"></i>
                      </div>
                      <div className="toggle-title font-weight-semibold pl-1">
                        <b>Explications :</b> Le SEO comment ca marche ?
                      </div>
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-chevron-down text-black-50"></i>
                        <i className="toggle-open icon-chevron-up color"></i>
                      </div>
                    </div>
                    <div className="text-black-50 pl-4">
                      <p className="mt-2">
                        Concrètement nous créeons des pages pour chaque ville que vous nous demandez. Nous pouvons
                        travailler aussi sur une zone géographique ou de
                        manière plus globale sur les plus grandes villes de France.<br /><br />

                        Ces pages sont des pages dites de "Présentation" et servent à mettre en avant votre entreprise
                        avec
                        vos créations et services.<br />

                        Une fois ces pages mises en place, dès lorsqu'un visiteur écris son sujet avec sa ville vous
                        avez
                        plus de chance d'apparaitre dans les résultats
                        de recherche et donc de toucher un potentiel futur client.<br /><br />

                        Par exemple un imprimeur avec un SEO local peux toucher une cible sur Lyon, Villeurbanne,
                        Venissieux
                        et d'autres villes très précises et donc
                        récuperer du traffic de ces dernières.
                      </p>
                    </div>
                  </div>

                  <div className="toggle faq pb-3 mb-4 avantages avantages">
                    <div className="toggle-header">
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-question-sign"></i>
                        <i className="toggle-open icon-question-sign"></i>
                      </div>
                      <div className="toggle-title font-weight-semibold pl-1">
                        <b>Avantages :</b> Touchez une audience précise.
                      </div>
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-chevron-down text-black-50"></i>
                        <i className="toggle-open icon-chevron-up color"></i>
                      </div>
                    </div>
                    <div className="text-black-50 pl-4">
                      <p className="mt-2">
                        Avec un SEO local configuré sur une ville et ses alentours vous pouvez toucher tout les
                        potentiels
                        visiteurs de ces dernieres et donc proposer
                        une service local qui donne plus confiance au futur client.<br /><br />

                        > <i>Un client venant de Lyon fera plus confiance à une entreprise locale qu'a une entreprise de
                        même taille étrangère ou sans
                        domiciliation connue.</i>
                      </p>
                    </div>
                  </div>

                  <div className="toggle faq pb-3 mb-4 avantages avantages">
                    <div className="toggle-header">
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-question-sign"></i>
                        <i className="toggle-open icon-question-sign"></i>
                      </div>
                      <div className="toggle-title font-weight-semibold pl-1">
                        <b>Avantages :</b> Touchez une audience plus large.
                      </div>
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-chevron-down text-black-50"></i>
                        <i className="toggle-open icon-chevron-up color"></i>
                      </div>
                    </div>
                    <div className="text-black-50 pl-4">
                      <p className="mt-2">
                        Avec un SEO local configuré sur les plus grandes villes de France vous pouvez toucher tout les
                        potentiels visiteurs des quatres coins
                        du pays et donc élargir l'audience auquelle vous proposer votre service.</p>

                    </div>
                  </div>

                  <div className="toggle faq pb-3 mb-4 setup setup">
                    <div className="toggle-header">
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-question-sign"></i>
                        <i className="toggle-open icon-question-sign"></i>
                      </div>
                      <div className="toggle-title font-weight-semibold pl-1">
                        <b>Mise en place :</b> Etape 1 : Créer la page de présentation.
                      </div>
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-chevron-down text-black-50"></i>
                        <i className="toggle-open icon-chevron-up color"></i>
                      </div>
                    </div>
                    <div className="text-black-50 pl-4">
                      <p className="mt-2">
                        La rédaction de la page de présentation est l'étape la plus importante, c'est cette page qui
                        doit
                        faire rester votre visiteur et lui donner
                        confiance. C'est pourquoi nous mettons en avant sur cette page vos projets, vos services les
                        plus
                        populaires et des informations dites de
                        "confiance" comme la livraison gratuite ou encore les différentes garanties que vous
                        proposez.</p>

                    </div>
                  </div>


                  <div className="toggle faq pb-3 mb-4 setup setup">
                    <div className="toggle-header">
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-question-sign"></i>
                        <i className="toggle-open icon-question-sign"></i>
                      </div>
                      <div className="toggle-title font-weight-semibold pl-1">
                        <b>Mise en place :</b> Etape 2 : Génerer les villes
                      </div>
                      <div className="toggle-icon color">
                        <i className="toggle-closed icon-chevron-down text-black-50"></i>
                        <i className="toggle-open icon-chevron-up color"></i>
                      </div>
                    </div>
                    <div className="text-black-50 pl-4">

                      <p className="mt-2">
                        Une fois votre besoin evalué par notre équipe nous vous proposons deux solutions; élargir votre
                        audience ou au contraire, proposer un service
                        plus local. Une fois le choix fait nous générons une liste de 80 villes en fonction de vos
                        paramètres et nous mettons en place immédiatement
                        la page de présentation sur ces dernières.
                      </p>


                    </div>
                  </div>


                </div>

              </div>

            </div>
          </div>
          <div className="section bg-transparent">
            <div className="container">
              <div className="row col-mb-30 align-content-stretch">
                <div className="col-12 col-md-12">
                  <a href="" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">

                        <div className="row">
                          <div className="col-7">
                            <div className="fbox-content">
                              <h3 className="nott ls0 text-larger">Optimisez votre présence en ligne.</h3>
                              <p className="text-smaller">Notre offre comprend les services suivants :</p>
                            </div>
                            <ul className="iconlist ml-3 mt-4 mb-0">
                              <li className="mb-2 text-muted">> Rédaction de la page principale</li>
                              <li className="mb-2 text-muted">> Génération de <b>80 pages</b> (1 page par ville)
                              </li>
                              <li className="mb-2 text-muted">> Outil de suivis des statistiques</li>
                              <li className="mb-2 text-muted">> Support réactif pour vous accompagner</li>
                            </ul>
                            <p className="ml-3 mt-4 mb-0">A partir de <b>250€</b> TTC</p>
                          </div>

                          <div className="col-5">
                            <div className="g_snippet text-left mobile" ng-show="g_device == 'mobile'"
                                 aria-hidden="false">
                              <div className="g_url">
                                <img src="https://ateros.fr/favicon.png" width="16" />
                                <span className="g_sitename ng-binding">Ateros</span>
                                <span className="g_urlpath ng-binding"></span>
                              </div>
                              <div className="g_title">
                            <span
                              ng-bind-html="g_title||'This is how the Page Title appears in search results but if it is too long it\'ll be truncated'|cut:true:80:' ...'"
                              className="ng-binding">Ateros - Création de site web pour les entreprises à <b>Lyon</b>.</span>
                              </div>
                              <div className="g_description">
                                <span className="g_date ng-binding ng-hide" ng-show="g_date" aria-hidden="true">Apr 04, 2021 · </span>
                                <span
                                  ng-bind-html="g_description||'This is the page description. Despite the meta description element\'s content, the text here depends on the search query and is rarely longer than 160 characters.'|cut:true:g_desc_char:' ...'"
                                  className="ng-binding">Ateros fournit des solutions numériques innovantes pour les entreprises et les indépendants.</span>
                              </div>
                              <div className="g_rating" ng-show="g_rating" aria-hidden="false">

                            <span>4.8 <i className="mdi mdi-star"></i><i className="mdi mdi-star"></i><i
                              className="mdi mdi-star"></i><i className="mdi mdi-star"></i><i className="mdi mdi-star" style={{ color: "#cccccc" }}></i> (848)</span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </a>
                </div>


              </div>
            </div>
          </div>

        </div>
      </section>
    </Layout>
  )
}