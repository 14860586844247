import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export function Hero({ image, children }) {
  return (
    <section id="page-title"
             className="page-title-parallax page-title-center page-title-dark include-header skrollable skrollable-between"
             style={{backgroundImage: "url('" + image + "')", backgroundSize: "cover", padding: "120px 0px"}}
             data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

      <div className="container clearfix mt-4">
        {children}
      </div>
    </section>
  )
}