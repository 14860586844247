import React from "react"
import Header from "./header"
import Footer from "./footer"

import "../../static/css/bootstrap.css"
import "../../static/css/style.css"
import "../../static/css/dark.css"
import "../../static/css/animate.css"
import "../../static/css/magnific-popup.css"
import "../../static/css/bs-switches.css"
import "../../static/css/custom.css"
import "../../static/css/colors.css"
import "../../static/css/fonts.css"
import "../../static/css/seo.css"

export default function Layout({ children }) {
  return (
    <>
      <Header/>
      <main>{children}</main>
      <Footer/>
    </>
  )
}