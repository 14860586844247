import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Header() {
  return <header className="transparent-header header-size-md sticky-top shadow">
    <div id="header-wrap">
      <div className="container">
        <div className="header-row">

          <div id="logo">
            <Link to="/" className="standard-logo" data-dark-logo="images/logo-dark.png"
                  activeClassName="current">
              <StaticImage
                src="../../static/img/logo-dark.png"
                alt="Canvas Logo"
                width={200} />
            </Link>
            <Link to="/" className="retina-logo">
              <StaticImage
                src="../../static/img/logo-wide.png"
                alt="Canvas Logo"
                width="200px"
                placeholder="blurred"/>
            </Link>
          </div>

          <div className="header-misc">

            <div id="top-search" className="header-misc-icon">
              <a href="#" id="top-search-trigger"><i className="icon-line-search"></i><i
                className="icon-line-cross"></i></a>
            </div>
            <a href="#" className="button button-rounded ml-3 d-none d-sm-block">Devis</a>

          </div>

          <div id="primary-menu-trigger">
            <svg className="svg-trigger" viewBox="0 0 100 100">
              <path
                d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
              <path d="m 30,50 h 40"></path>
              <path
                d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
            </svg>
          </div>

          <nav className="primary-menu with-arrows">
            <ul className="menu-container">
              <li className="menu-item">
                <Link className="menu-link" to="/" activeClassName="current">
                  <div>Accueil</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link className="menu-link" to="/about" activeClassName="current">
                  <div>À propos</div>
                </Link>
              </li>
              <li className="menu-item mega-menu">
                <div className="menu-link">
                  <div>Services</div>
                </div>
                <div className="mega-menu-content mega-menu-style-2 px-0">
                  <div className="container">
                    <div className="row">

                      <Link to="/designer"
                            className="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4"
                            activeClassName="current">
                        <div className="feature-box">
                          <div className="fbox-icon mb-2">
                            <img
                              src="https://static-cdn.ateros.fr/file/ateros/dL60a8aKpiJeh3Kd6Nk3o7P3CDHbniOWUqh9jFw3.png"
                              alt="Feature Icon" className="bg-transparent rounded-0" />
                          </div>
                          <div className="fbox-content">
                            <h3 className="nott ls0">Designer</h3>
                            <p>Proposez du Print On Demand avec un outil adapté et performant.</p>
                          </div>
                        </div>
                      </Link>

                      <a href="#"
                         className="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                        <div className="feature-box">
                          <div className="fbox-icon mb-2">
                            <img
                              src="https://static-cdn.ateros.fr/file/ateros/GXNm9p1IEIB05jyiuGsKMVqLMJJv3MIrrB7XjCnA.png"
                              alt="Feature Icon" className="bg-transparent rounded-0" />
                          </div>
                          <div className="fbox-content">
                            <h3 className="nott ls0">Social</h3>
                            <p>Création de contenu pour tous vos réseaux sociaux.</p>
                          </div>
                        </div>
                      </a>

                      <a href="#"
                         className="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                        <div className="feature-box">
                          <div className="fbox-icon mb-2">
                            <img
                              src="https://static-cdn.ateros.fr/file/ateros/3n9z4XHE07Mwk2PJeBgbpT0DawEhtl57TmEGVM9y.png"
                              alt="Feature Icon" className="bg-transparent rounded-0" />
                          </div>
                          <div className="fbox-content">
                            <h3 className="nott ls0">Shop</h3>
                            <p>Développez votre business avec un site moderne et élégant.</p>
                          </div>
                        </div>
                      </a>
                      <a href="#"
                         className="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                        <div className="feature-box">
                          <div className="fbox-icon mb-2">
                            <img
                              src="https://static-cdn.ateros.fr/file/ateros/zxHDIcdNYcyoT9EXupwmykBZRUfSzl119cQw255f.png"
                              alt="Feature Icon" className="bg-transparent rounded-0" />
                          </div>
                          <div className="fbox-content">
                            <h3 className="nott ls0">Invoice</h3>
                            <p>Facturation nouvelle génération.
                              Découvrez Ateros Invoice.</p>
                          </div>
                        </div>
                      </a>
                      <a href="#"
                         className="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                        <div className="feature-box">
                          <div className="fbox-icon mb-2">
                            <img
                              src="https://static-cdn.ateros.fr/file/ateros/a7k4MNguDKbFPFHeA6UJSCOmApba4HLZgRcxv6sV.png"
                              alt="Feature Icon" className="bg-transparent rounded-0" />
                          </div>
                          <div className="fbox-content">
                            <h3 className="nott ls0">Pay</h3>
                            <p>Acceptez les paiements en ligne sur toutes vos plateformes.</p>
                          </div>
                        </div>
                      </a>
                      <a href="#"
                         className="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                        <div className="feature-box">
                          <div className="fbox-icon mb-2">
                            <img
                              src="https://static-cdn.ateros.fr/file/ateros/iA7XlPpBk5qnGfWIYbK2HuO6RfdVn7Fnr5T55CYR.png"
                              alt="Feature Icon" className="bg-transparent rounded-0" />
                          </div>
                          <div className="fbox-content">
                            <h3 className="nott ls0">Host </h3>
                            <p>Hébergement d'application et site web.
                              Serveurs virtuels, hébergement web et mail.</p>
                          </div>
                        </div>
                      </a>
                      <div className="mega-menu-column sub-menu-container col-12 p-0">
                        <a href="#"
                           className="button button-rounded button-large nott ls0 btn-block text-center m-0 rounded-0 py-3 button-light ">Tous
                          nos services <i className="icon-line-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-item">
                <Link className="menu-link" to="/seo" activeClassName="current">
                  <div>SEO</div>
                </Link>
              </li>
              <li className="menu-item"><a className="menu-link" href="#">
                <div>Création web</div>
              </a></li>
              <li className="menu-item"><a className="menu-link" href="#">
                <div>Contact</div>
              </a></li>
            </ul>

          </nav>
        </div>
      </div>
    </div>
  </header>
}
